body { 
  min-height: 100vh;
  background-color: #fcfcfc;
}

.fc-day:hover {
  background-color: #fafafa;
}

.fc .fc-daygrid-day-number {
  width: 100%;
}

.fc-day:hover .MuiIconButton-root {
  opacity: 1 !important;
}